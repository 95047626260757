<template>
  <span class="svg-icon svg-icon-primary svg-icon-sm"
    ><!--begin::Svg Icon | path:/var/www/preview.keenthemes.com/metronic/releases/2021-05-14-112058/theme/html/demo1/dist/../src/media/svg/icons/Navigation/Plus.svg--><svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
    >
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1" />
        <rect
          fill="#000000"
          opacity="0.3"
          transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) "
          x="4"
          y="11"
          width="16"
          height="2"
          rx="1"
        />
      </g></svg
    ><!--end::Svg Icon--></span
  >
</template>
