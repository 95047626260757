import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "row align-items-center" }
const _hoisted_2 = {
  key: 0,
  class: "col-md-2 text-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_PlusIcon = _resolveComponent("PlusIcon")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(_ctx.style_select)
      }, [
        _createVNode(_component_el_select, {
          class: "form-control form-control-lg form-control-solid",
          modelValue: _ctx.valueItemSelected,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueItemSelected) = $event)),
          filterable: "",
          placeholder: "Please enter a keyword",
          onChange: _ctx.valueModal
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.inputValue.options, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: item[_ctx.itemLabel],
                value: item.id
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", null, _toDisplayString(item[_ctx.itemLabel]), 1)
                ]),
                _: 2
              }, 1032, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 8, ["modelValue", "onChange"])
      ], 2),
      (_ctx.addButtonPlus)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openDialog = true)),
              class: "btn btn-success btn-sm font-weight-bold mr-2"
            }, [
              _createVNode(_component_PlusIcon)
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.openDialog,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.openDialog) = $event)),
      width: "40%",
      onClose: _ctx.resetModal
    }, null, 8, ["modelValue", "onClose"])
  ], 64))
}