
import { defineComponent, ref, onMounted, onUpdated } from "vue";
import ApiService from "@/core/services/ApiService";
import PlusIcon from "@/components/icons/PlusIcon.vue";

export default defineComponent({
  props: {
    itemModel: String,
    resourceApi: String,
    itemLabel: String,
    addButtonPlus: Boolean,
    valueModel: String
  },
  components: {
    PlusIcon
  },

  setup(props, { emit }) {
    const openDialog = ref(false);
    //let valueModel: any;
    const inputValue = ref({
      options: []
    });
    const valueItemSelected = ref("");
    const getResource = () => {
      ApiService.get("/api/" + props.resourceApi).then(({ data }) => {
        inputValue.value.options = data;
      });
    };

    const style_select = ref("col-md-6");

    const styles = () => {
      if (props.addButtonPlus) {
        style_select.value = "col-md-10";
      } else {
        style_select.value = "col-md-6";
      }
    };

    const setValues = () => {
      if (props.valueModel != null) {
        valueItemSelected.value = props.valueModel;
      }
    };

    const valueModal = value => {
      emit("emitModalValue", value);
    };

    onMounted(() => {
      getResource();
      styles();
    });

    onUpdated(() => {
      setValues();
    });

    return {
      setValues,
      inputValue,
      getResource,
      style_select,
      styles,
      openDialog,
      valueModal,
      valueItemSelected
    };
  }
});
