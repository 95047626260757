
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { translate } from "@/core/helpers/functions";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import Select from "@/components/Select.vue";
import { useI18n } from "vue-i18n";
interface FormCompany {
  id?: number;
  companyGroupId?: number;
  name: string;
  cif: string;
  address: string;
  email: string;
  website: string;
  phone: string;
  status: string;
  logo: string;
  logodoc: string;
}

export default defineComponent({
  name: "CompaniesCU",
  components: {
    Field,
    Form,
    ErrorMessage,
    Select,
  },
  props: {
    typeEvent: String,
    item: Object,
  },
  setup(props, { emit }) {
    const { t } = useI18n({ useScope: "global" });
    const route = useRoute();
    const submitButton = ref<HTMLElement | null>(null);
    const title = ref("");
    let logo: any;
    let logodoc: any;
    const companiesGroupsApi = ref("companiesGroups");
    const labelCompaniesGroup = ref("name");
    const companiesGroupSelect = ref("");
    const form = ref<FormCompany>({
      name: "",
      cif: "",
      address: "",
      email: "",
      website: "",
      phone: "",
      status: "",
      logo: "",
      logodoc: "",
    });
    const inputId = ref({
      loading: false,
      options: [],
      list: [],
    });
    const inputCompanyG = ref({
      loading: false,
      options: [],
      list: [],
    });

    const getCompanies = () => {
      ApiService.get("/api/companies").then(({ data }) => {
        inputId.value.list = data;
      });
    };
    const selectCompanies = (query) => {
      if (query !== "") {
        inputId.value.loading = true;
        setTimeout(() => {
          inputId.value.loading = false;
          inputId.value.options = inputId.value.list.filter(
            (item: Record<string, any>) => {
              return item.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
            }
          );
        }, 200);
      } else {
        inputId.value.options = [];
      }
    };

    const setCompany = (id) => {
      const item = inputId.value.list.find((item: Record<string, any>) => {
        return item.id === id;
      }) || {
        id: "",
        company_group_id: "",
        name: "",
        CIF: "",
        address: "",
        email: "",
        website: "",
        phone: "",
        status: "",
        logo: "",
        logodoc: "",
      };
      form.value.name = item.name;
      form.value.companyGroupId = Number(item.company_group_id);
      form.value.cif = item.CIF;
      form.value.address = item.address;
      form.value.email = item.email;
      form.value.website = item.website;
      form.value.phone = item.phone;
      form.value.status = item.status;
      form.value.logo = item.logo;
      form.value.logodoc = item.logodoc;
    };

    const emitModalValue = (id) => {
      form.value.companyGroupId = id;
    };

    const previewImage = (event) => {
      const [file] = event.target.files;
      if (file) {
        form.value[event.target.name] = URL.createObjectURL(file);
        if (event.target.name === "logo") {
          logo = event.target.files[0];
        } else {
          logodoc = event.target.files[0];
        }
      }
    };

    const removeImage = (input) => {
      form.value[input] = "media/defaults/blank.png";
      if (input === "logo") {
        logo = "";
      } else {
        logodoc = "";
      }
    };

    onMounted(() => {
      const nameRoute = route.name;

      if (nameRoute === "companiesCreate" || props.typeEvent === "create") {
        title.value = t("createNewBusiness");
      } else if (
        nameRoute === "companiesUpdate" ||
        props.typeEvent === "update"
      ) {
        title.value = "Actualizar empresa";
      }

      if (props.item && Object.keys(props.item).length > 0) {
        companiesGroupSelect.value = props.item.company_group.name;
        form.value.id = props.item.id;
        form.value.companyGroupId = props.item.company_group_id;
        form.value.name = props.item.name;
        form.value.cif = props.item.cif;
        form.value.address = props.item.address ?? "";
        form.value.email = props.item.email ?? "";
        form.value.website = props.item.website;
        form.value.phone = props.item.phone ?? "";
        form.value.status = "";
        form.value.logo = props.item.logo;
        form.value.logodoc = props.item.logodoc;
      }
      if (nameRoute === "companiesUpdate") {
        getCompanies();
      }

      if (!props.typeEvent) {
        const nav =
          nameRoute == "companiesCreate"
            ? "companiesCreate"
            : "companiesUpdate";
        setCurrentPageBreadcrumbs(nav, ["companies", "companies"], false);
      }
    });

    const createCompanies = () => {
      const formData = new FormData();
      formData.append(
        "company_group_id",
        form.value.companyGroupId ? form.value.companyGroupId.toString() : ""
      );
      formData.append("name", form.value.name);
      formData.append("cif", form.value.cif);
      formData.append("website", form.value.website);
      formData.append("logo", logo);
      formData.append("logodoc", logodoc);

      ApiService.postAttach("/api/companies", formData)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
        })
        .catch(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const updateCompanies = () => {
      const formData = new FormData();
      formData.append(
        "company_group_id",
        form.value.companyGroupId ? form.value.companyGroupId.toString() : ""
      );
      formData.append("name", form.value.name);
      formData.append("cif", form.value.cif);
      formData.append("website", form.value.website);
      formData.append("logo", logo);
      formData.append("logodoc", logodoc);
      ApiService.postAttach(`/api/companies/update/${form.value.id}`, formData)
        .then(({ data }) => {
          emit("success", data);
          submitButton.value?.removeAttribute("data-kt-indicator");
        })
        .catch(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const vcompanies = Yup.object().shape({
      name: Yup.string().required(translate("rname")).label("Name"),
      companyGroupId: Yup.string().required().label("Companies group"),
      cif: Yup.string().label("CIF/NIF"),
      address: Yup.string().required().label("Address"),
      email: Yup.string().required().email().label("Email"),
      website: Yup.string().url().nullable(),
    });

    const onSubmitCompany = () => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
        if (form.value.id) {
          updateCompanies();
        } else {
          createCompanies();
        }
      }
    };

    return {
      route,
      title,
      submitButton,
      vcompanies,
      form,
      logo,
      logodoc,
      inputId,
      inputCompanyG,
      onSubmitCompany,
      createCompanies,
      updateCompanies,
      getCompanies,
      selectCompanies,
      setCompany,
      emitModalValue,
      previewImage,
      removeImage,
      companiesGroupsApi,
      labelCompaniesGroup,
      companiesGroupSelect,
    };
  },
});
